import { generateClient } from "@aws-amplify/api";
import { createContext, useContext, useEffect, useState } from "react";
import { publish } from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import { chatService, chatStorageService } from "../services/chatService";
import { useAuth } from "./auth";

interface ChatContextData {
  getChatMessages: () => any[];
  setChatMessages: (messages: any) => void;
  messageNumber: number;
  setMessageNumber: (value: number) => void;
  sendMessage: (message: any) => void;
}

type ChatProviderProps = {
  children: React.ReactNode;
};

const ChatContext = createContext<ChatContextData>({} as ChatContextData);
export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { user, channelId } = useAuth();
  const [messages, setMessages] = useState<any[]>([]);
  const [chatNewMessage, setChatNewMessage] = useState(0);
  const client = generateClient();

  async function sendMessage(message: any) {
    if (!channelId) return;
    const sendMessageData = {
      channel_id: channelId,
      user: {
        id: user.id,
        name: user.name.toLowerCase().split(" ")[0],
      },
      message,
    };
    const messageData = {
      channel_id: channelId,
      _id: Date.now(),
      user: {
        id: user.id,
        name: user.name.toLowerCase().split(" ")[0],
      },
      text: message,
    };
    const response = client.graphql({
      query: publish,
      variables: {
        data: JSON.stringify(sendMessageData),
        name: channelId,
      },
    });
    setMessages((previousMessages) => {
      let newMessages = [...previousMessages, messageData];
      chatStorageService.setChatMessages(newMessages);
      return newMessages;
    });
  }

  function setChatMessages(messages: any) {
    setMessages(messages);
  }

  function getChatMessages() {
    return messages;
  }

  function setMessageNumber(number: number) {
    setChatNewMessage(number);
  }

  async function getAllMessages(userId: string) {
    let messages = await chatStorageService.getMessages();
    if (messages?.length >= 1) {
      setChatMessages(messages);
    } else {
      messages = await chatService.getChatMessages(userId);
      chatStorageService.setChatMessages(messages);
    }
    setChatMessages(messages);
  }

  useEffect(() => {
    const initialLoading = async () => {
      if (user.id) {
        await getAllMessages(user.id);
      }
    };
    initialLoading();
  }, [user]);

  useEffect(() => {
    if (!channelId) return;
    const onCreateNotification = client
      .graphql({
        query: subscriptions.subscribe,
        variables: { name: channelId },
      })
      .subscribe({
        next: async ({ data }) => {
          const dataMessage = JSON.parse(data.subscribe.data);
          if (dataMessage.support_id) {
            const message = {
              _id: Date.now(),
              text: dataMessage.message,
              createdAt: new Date(),
              user: {
                _id: 1,
                name: "suporte",
                avatar:
                  "https://snackly-images.s3.amazonaws.com/snackly-145x145.png",
              },
            };
            setChatNewMessage((prev) => (prev += 1));
            setMessages((previousMessages) => {
              let newMessages = [...previousMessages, message];
              chatStorageService.setChatMessages(newMessages);
              return newMessages;
            });
          }
        },
        error: (error) => console.warn(error),
      });
    return () => onCreateNotification.unsubscribe();
  }, [channelId]);

  return (
    <ChatContext.Provider
      value={{
        getChatMessages,
        setChatMessages,
        messageNumber: chatNewMessage,
        setMessageNumber,
        sendMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export function useChat() {
  return useContext(ChatContext);
}
