import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useEffect, useRef, useState } from "react";
import { useChat } from "../../contexts/chat";
import { colorWhite } from "../../utils/colors";
import {
  BallonContainer,
  BallonMessage,
  BallonText,
  Container,
  Input,
  InputContainer,
  MessageContainer,
  SendButton,
} from "./styles";
import Loading from "../../components/Loading";
import { chatService, chatStorageService } from "../../services/chatService";
import { useAuth } from "../../contexts/auth";

const ChatSupport: React.FC = ({ navigation }) => {
  const { setMessageNumber, getChatMessages, sendMessage } = useChat();
  const { setChannel, user } = useAuth();
  const [messages, setMessages] = useState<any[]>([]);
  const [messageInput, setMessageInput] = useState("");
  const [loading, setLoading] = useState(true);
  const scrollViewRef = useRef(null);

  async function handleSendMessage() {
    setMessageInput("");
    sendMessage(messageInput);
  }

  function scrollViewSizeChanged(height: number) {
    scrollViewRef.current?.scrollTo({ x: 0, y: height, animated: false });
  }

  async function getChannel() {
    try {
      setLoading(true);
      const getChannelId = await chatService.getChannelId(user);
      if (getChannelId) {
        await chatStorageService.setChannelId(getChannelId);
        setChannel(getChannelId);
      }
    } catch (error) {
      console.log("[ERROR]: ", error);
    } finally {
      setLoading(false);
    }
  }

  async function loadChatMessages() {
    setLoading(true);
    setMessages(getChatMessages());
    setLoading(false);
  }

  useEffect(() => {
    setMessageNumber(0);
    loadChatMessages();
  }, [getChatMessages()]);

  useEffect(() => {
    setMessageNumber(0);
    getChannel();
    loadChatMessages();
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading text="Carregando suas mensagens..." />
      ) : (
        <MessageContainer
          ref={scrollViewRef}
          onContentSizeChange={(_, height) => {
            scrollViewSizeChanged(height);
          }}
        >
          {messages.map((message) => (
            <BallonContainer
              key={message._id}
              isSupport={message.user.name == "suporte"}
            >
              <BallonMessage isSupport={message.user.name == "suporte"}>
                <BallonText isSupport={message.user.name == "suporte"}>
                  {message.text}
                </BallonText>
              </BallonMessage>
            </BallonContainer>
          ))}
        </MessageContainer>
      )}

      <InputContainer>
        <Input
          enterKeyHint={"send"}
          placeholder="Enviar mensagem"
          placeholderTextColor="#999999"
          value={messageInput}
          onChangeText={(text: string) => setMessageInput(text)}
        />
        <SendButton onPress={handleSendMessage}>
          <MaterialCommunityIcons name="send" color={colorWhite} size={28} />
        </SendButton>
      </InputContainer>
    </Container>
  );
};

export default ChatSupport;
