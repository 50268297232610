import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { Pressable, RefreshControl, Switch, Text, View } from "react-native";
import { Button, Input } from "react-native-elements";
import Loading from "../../components/Loading";
import MachineSelectedView from "../../components/MachineSelectedView";
import ProductItem from "../../components/ProductItem";
import Warning from "../../components/Warning";
import { AlertOS } from "../../hooks/AlertOS";
import { NO_ITENS } from "../../image";
import { BasketItem } from "../../models/basketItem";
import { MachineIdentification } from "../../models/machine";
import { ProductBasket } from "../../models/product";
import { TotemRoutesStackTypes } from "../../routes/totem.routes";
import api from "../../services/api";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  IconSettings,
  InfoPurchase,
  ProductContainer,
  ProductList,
  ProductsAvaliable,
  PurchaseTotal,
  SettingsBtnGroup,
  SettingsContainer,
  SettingsModalContainer,
  SwitchContainer,
  SwitchText,
} from "./styles";
import { useAuth } from "../../contexts/auth";
import MyModal from "../../components/MyModal";
import MaterialIcons from "@expo/vector-icons/build/MaterialIcons";
import { ActionColor, colorRed, InfoColor } from "../../utils/colors";
import CustomKeyboard from "../../components/CustomKeyboard";

interface MachineBasketTotemProps {
  machineId: string;
  machine: MachineIdentification;
}

const MachineBasketTotem: React.FC = () => {
  const navigation = useNavigation<TotemRoutesStackTypes>();
  const route =
    useRoute<RouteProp<Record<string, MachineBasketTotemProps>, string>>();
  const { machineId } = route.params;
  const { signOut } = useAuth();
  const [machine, setMachine] = useState<MachineIdentification>(
    route.params.machine
  );
  const [loading, setLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState<any[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [isSettingModalOpen, setIsSettingsModalOpen] = useState(false);
  const [hasKeyBoard, setHasKeyboard] = useState(false);
  const inputScanREF = useRef(null);
  let count = 0;
  let total = getTotal();

  function getTotal() {
    if (!cartProducts) return 0;
    return cartProducts.reduce((acc, cur) => {
      acc += cur.price * cur.quantity;
      return acc;
    }, 0);
  }

  async function getMachine() {
    try {
      setLoading(true);
      const response = await api.get(`/machine/${machineId}`);
      const { machines } = response.data;
      let getCartProducts = machines?.products.map((item) => {
        const { product } = item;
        return {
          id: product.id,
          image: product.image,
          name: product.name,
          price: product.price,
          maxQuantity: item.quantity,
          barCode: product.bar_code,
          quantity: 0,
        };
      });
      setCartProducts(getCartProducts);
      setMachine(machines);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function saveCartItems(items: BasketItem[]) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  function handlePayment() {
    if (!total) {
      AlertOS({
        title: "Selecione um produto",
        message:
          "Não existem produtos no carrinho. Selecione um produto para prosseguir",
        toastType: "info",
      });
      return;
    }
    let items = cartProducts.filter((cartProduct) => cartProduct.quantity != 0);
    navigation.navigate("PaymentTotem", {
      machine,
      total,
      items,
    });
  }

  function updateCartItem(barCode: string) {
    const getCartProduct = cartProducts.find(
      (cartItem) => cartItem.barCode === barCode
    );
    if (getCartProduct) {
      let updatedCartProducts = [...cartProducts].map((cartItem) =>
        cartItem.barCode == barCode
          ? { ...cartItem, quantity: (cartItem.quantity += 1) }
          : cartItem
      );
      setCartProducts(updatedCartProducts);
    } else {
      AlertOS({
        title: "Produto Não Encontrado",
        message: "Produto Não Cadastrado no Sistema",
      });
    }
  }

  function handleScanBarCode(barCodeScanned: string) {
    if (barCodeScanned.length >= 13) {
      updateCartItem(barCodeScanned);
    }
  }

  function handleBarCodeFocus() {
    setTimeout(() => {
      if (inputScanREF.current) {
        inputScanREF.current.focus();
      }
    }, 1000);
  }

  function renderItem({ item }: { item: ProductBasket }) {
    return (
      <ProductItem
        {...item}
        cartItems={cartProducts}
        updateCartItems={setCartProducts}
      />
    );
  }

  useEffect(() => {
    saveCartItems(cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    getMachine();
    // handleBarCodeFocus();
  }, []);

  return loading ? (
    <Loading text="Carregando..." />
  ) : (
    <Container>
      <MachineSelectedView machine={machine} />
      {/* <IconSettings>
        <MaterialIcons
          onPress={() => setIsSettingsModalOpen(!isSettingModalOpen)}
          color={ActionColor}
          name="settings"
          size={24}
          style={{
            marginRight: 14,
          }}
        />
      </IconSettings> */}
      {/* <MyModal visible={isSettingModalOpen}>
        <SettingsModalContainer>
          <SettingsContainer>
            <SwitchContainer>
              <SwitchText>Ativar Teclado?</SwitchText>
              <Switch
                value={hasKeyBoard}
                onValueChange={(value) => {
                  setHasKeyboard(value);
                }}
              />
            </SwitchContainer>
            <SettingsBtnGroup>
              <Button
                buttonStyle={{
                  paddingHorizontal: 28,
                  borderColor: colorRed,
                }}
                titleStyle={{
                  color: colorRed,
                }}
                title="Cancelar"
                type="outline"
                onPress={() => setIsSettingsModalOpen(!isSettingModalOpen)}
              />
              <Button
                buttonStyle={{
                  backgroundColor: ActionColor,
                  paddingHorizontal: 40,
                }}
                titleStyle={{
                  color: "#fff",
                }}
                type="solid"
                title="Salvar Configurações"
              />
            </SettingsBtnGroup>
          </SettingsContainer>
        </SettingsModalContainer>
      </MyModal> */}
      <Input
        containerStyle={{
          position: "absolute",
          opacity: 1,
          bottom: 0,
          left: -160,
          borderBottomWidth: 0,
        }}
        inputContainerStyle={{
          borderBottomWidth: 0,
        }}
        style={{
          color: "transparent",
        }}
        keyboardType="numeric"
        ref={inputScanREF}
        onChangeText={handleScanBarCode}
        onBlur={handleBarCodeFocus}
        value=""
      />

      {machine?.products.length == 0 && !refreshing && (
        <>
          <Warning
            imageSource={NO_ITENS}
            title="Não há produtos nesta máquina"
          ></Warning>
          <Button
            title="Buscar Produtos"
            loading={loading}
            onPress={getMachine}
            onLongPress={() => {
              if (count >= 6) {
                signOut();
              }
              count += 1;
            }}
          />
        </>
      )}
      {refreshing ? (
        <Loading text={"Buscando produtos..."} />
      ) : (
        <ProductContainer
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={getMachine}
              enabled={true}
            />
          }
        >
          <ProductList
            numColumns={2}
            data={cartProducts}
            keyExtractor={(item: ProductBasket) => item.id}
            renderItem={renderItem}
          />
        </ProductContainer>
      )}
      <InfoPurchase>
        <ProductsAvaliable>Itens a venda</ProductsAvaliable>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </InfoPurchase>
      <Button
        title="Escolher forma de Pagamento"
        loading={loading}
        onPress={handlePayment}
        onLongPress={() => {
          if (count >= 6) {
            signOut();
          }
          count += 1;
          // handleBarCodeFocus();
        }}
      />
      <Button
        title="Buscar Produtos"
        loading={loading}
        onPress={() => getMachine()}
        type="clear"
      />
    </Container>
  );
};

export default MachineBasketTotem;
