import { useNavigation } from "@react-navigation/native";
import { MachineIdentification } from "../../models/machine";

import { MaterialIcons } from "@expo/vector-icons";
import { IMGMACHINE } from "../../image";
import { formatDistance } from "../../utils/formatNumber";
import {
  Distance,
  MachineAddress,
  MachineDescription,
  MachineImage,
  MachineInfo,
  MachineSelected,
  MachineStar,
  MachineTitle,
} from "./styles";

interface MachineSelectedViewProps {
  machine: MachineIdentification;
}

function MachineSelectedView({ machine }: MachineSelectedViewProps) {
  const navigation = useNavigation();
  return (
    <MachineSelected onPress={() => navigation.goBack()}>
      <MachineImage
        source={machine?.logo ? { uri: machine?.logo } : IMGMACHINE}
      />
      <MachineInfo>
        <MachineTitle>{machine?.name}</MachineTitle>
        <MachineDescription>
          <MaterialIcons name="star" size={20} color="#FF7C01" />
          <MachineStar>{machine?.star ? machine?.star : "Nova! "} </MachineStar>
          {/* <Categories>{machine?.V_CATEGORIES?.join(' | ')}</Categories> */}
          <Distance>
            {machine?.distance
              ? " a " + formatDistance(machine?.distance)
              : "distância desconhecida"}
          </Distance>
        </MachineDescription>
        <MachineDescription>
          <MachineAddress>{machine?.address}</MachineAddress>
        </MachineDescription>
      </MachineInfo>
    </MachineSelected>
  );
}

export default MachineSelectedView;
