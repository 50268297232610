import AsyncStorage from "@react-native-async-storage/async-storage";
import { User } from "../models/user";
import apiChat from "./apiChat";

interface Message {
  message_id: string;
  message: string;
  created_at: string;
  user: {
    id: number,
    name: string
  }
}

const chatService = {
  async getChatMessages(userId: string) {
    const response = await apiChat.get(`/chat/messages/${userId}`);
    if (response.data.length <= 1) return []
    const messages = response.data.map((message: Message) => {
      return {
        _id: message.message_id,
        text: message.message,
        createdAt: new Date(message.created_at),
        user: {
          _id: message.user ? message.user.id : 1,
          name: message.user ? message.user.name : "suporte",
          avatar:
            "https://snackly-images.s3.amazonaws.com/snackly-145x145.png",
        },
      };
    });
    return messages
  },
  async getChannelId(user: User) {
    let channelId = await chatStorageService.getChannelId()
    if (channelId) return channelId;
    const response = await apiChat.post('/chat/channel', { user_id: user.id, name: user.name });
    channelId = response.data.channel_id
    if (channelId) chatStorageService.setChannelId(channelId);
    return channelId;
  },
}

const chatStorageService = {
  setChannelId: async (channelId: string) => {
    await AsyncStorage.setItem("@Chat:channelId", channelId);
  },
  getChannelId: async (): Promise<string | null> => {
    const storegedChannelId = await AsyncStorage.getItem("@Chat:channelId");
    return (storegedChannelId) ? storegedChannelId : null;
  },
  setChatMessages: async (messages: any[] | null) => {
    await AsyncStorage.setItem("@Chat:messages", JSON.stringify(messages))
  },
  getMessages: async (): Promise<any[]> => {
    const storegedChatMessages = await AsyncStorage.getItem("@Chat:messages");
    return storegedChatMessages ? JSON.parse(storegedChatMessages) : [];
  }
}

export { chatService, chatStorageService }