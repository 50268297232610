import React, { createContext, useContext, useEffect, useState } from "react";
import { User } from "../models/user";
import api from "../services/api";
import * as auth from "../services/auth";
import userService from "../services/userservice";
import apiChat from "../services/apiChat";
import { chatStorageService } from "../services/chatService";

interface AuthContextData {
  machineId: string;
  signed: boolean;
  user: User;
  loading: boolean;
  signIn(email: string, password: string): void;
  signUp(
    email: string,
    password: string,
    name: string,
    phoneNumber: string
  ): void;
  confirmSignUp(email: string, code: string): void;
  resendSignUpCode(email: string): void;
  forgotPassword(email: string): void;
  confirmForgotPassword(email: string, password: string, code: string): void;
  signOut(): void;
  setContextUser(user: User): void;
  setScanMachineId(id: string): void;
  channelId: string | null;
  setChannel(value: string): void;
}

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [channelId, setChannelId] = useState<string | null>(null);
  const [machineId, setMachineId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStoregedData = async () => {
      const storegedUser = await userService.getLogedUser();
      const storegedToken = await userService.getLogedToken();
      const storagedChannelId = await chatStorageService.getChannelId();
      if (storagedChannelId) {
        setChannelId(storagedChannelId);
      }
      if (storegedUser && storegedToken) {
        api.defaults.headers["Authorization"] = `${storegedToken}`;
        apiChat.defaults.headers["Authorization"] = `${storegedToken};`;
        setUser(storegedUser);
        setLoading(false);
      } else if (!storegedUser) {
        setLoading(false);
      }
    };
    loadStoregedData();
  }, []);

  function setChannel(value: string) {
    setChannelId(value);
  }

  function setScanMachineId(id: string) {
    setMachineId(id);
  }

  async function signIn(email: string, password: string) {
    const response = await auth.signIn(email, password);
    if (response) {
      const { user, idToken, accessToken, refreshToken } = response;
      if (user && idToken) {
        setUser(user);
        api.defaults.headers["Authorization"] = `${accessToken}`;
        apiChat.defaults.headers["Authorization"] = `${accessToken};`;
        await userService.setLogedUser(user);
        await userService.setLogedToken(accessToken);
        if (refreshToken) {
          await userService.setRefreshToken(refreshToken);
        }
      }
    }
  }

  async function signUp(
    email: string,
    password: string,
    name: string,
    phoneNumber: string
  ) {
    await auth.signUp(email, password, name, phoneNumber);
  }

  async function confirmSignUp(email: string, code: string) {
    await auth.confirmSignUp(email, code);
  }

  async function resendSignUpCode(email: string) {
    await auth.resendSignUpCode(email);
  }

  async function forgotPassword(email: string) {
    await auth.forgotPassword(email);
  }

  async function confirmForgotPassword(
    email: string,
    password: string,
    code: string
  ) {
    await auth.confirmForgotPassword(email, password, code);
  }

  async function signOut() {
    await userService.clear();
    setUser({} as User);
  }

  async function setContextUser(user: User) {
    setUser(user);
    await userService.setLogedUser(user);
  }

  return (
    <AuthContext.Provider
      value={{
        machineId,
        signed: !!(user && user?.status >= 1),
        user,
        loading,
        signIn,
        signUp,
        confirmSignUp,
        resendSignUpCode,
        forgotPassword,
        confirmForgotPassword,
        signOut,
        setContextUser,
        setScanMachineId,
        channelId,
        setChannel,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
