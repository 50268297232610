import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';

export const Container = styled.View`
  margin: 0px;
  background: #FFFFFF;
  padding: 5px 15px 0px 15px;
  flex: 1;
`;

export const ProductContainer = styled.View`
  display: flex;
  height: 60%;
  padding: 10px 0;
`;

export const ProductList = styled.FlatList`
`;

export const InfoPurchase = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 30px 0px;
`;

export const ProductsAvaliable = styled.Text`
  font-weight: bold;
  color: #333;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 0px 10px;
  margin-top: 0px;
  margin-right: 0px;
`;

export const PurchaseTotal = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
  margin-top: 0px;
  margin-left: 0px;
  padding: 10px 10px 0px 0px;
  align-items: flex-end;
  text-align: right;
  line-height: 14px;
`;

export const IconSettings = styled.Pressable`

`;


export const SettingsModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  background-color: "gray",
`;

export const SettingsContainer = styled.View`
  background-color: #fff;
  padding: 24px;
`;

export const SwitchContainer = styled.View`
  flex-direction: row;
  margin: ${normalizeLimit(18)}px 0;
  gap: 8px;
`;

export const SwitchText = styled.Text`
  font-size: ${normalizeLimit(18)}px;
`;

export const SettingsBtnGroup = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;