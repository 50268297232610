import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';
import { normalizeLimit } from '../../utils/normalizeLimit';

export const Product = styled.TouchableOpacity`
  background-color: '#ffff00';
  display: flex;
  padding: 5px;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 4px;  
`;

export const ItemTitle = styled.Text`
  text-align: center;
  font-weight: bold;
  font-size: ${normalizeLimit(18)}px;
  color: #333;
`;

export const ImageContainer = styled.View`
  width: ${normalizeLimit(60)}px;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 6px 0;
`;

export const Quantity = styled.Text`
  position: absolute;
  top: 0;
  right: -8px;
  z-index: 5;
  font-size: ${normalizeLimit(12)}px;
  margin-left: 6px;
  background: ${ActionColor};
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
`;

export const Image = styled.Image`
  width: ${normalizeLimit(60)}px;
  height: ${normalizeLimit(60)}px;
  border-radius: ${normalizeLimit(30)}px;
`;

export const ItemContainer = styled.View`

`;


export const ItemQuantify = styled.View`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Price = styled.Text`
  text-align: center;
  font-weight: bold;
  font-size: ${normalizeLimit(18)}px;
  color: #333;
`;